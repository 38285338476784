import * as React from "react"
import Header from "../components/main/header"
import Footer from "../components/main/footer"
import Body from "../components/main/body"
const ProjectsPage = () => {
    return (
      <main>
        <title>Projects</title>
        <div>
          <Header />
          <Body>
            Projects Body
          </Body>
        </div>
        <Footer/>      
      </main>
    )
  }
  
  export default ProjectsPage